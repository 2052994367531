// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-american-challenge-batteryrecycling-js": () => import("./../../../src/pages/american-challenge/batteryrecycling.js" /* webpackChunkName: "component---src-pages-american-challenge-batteryrecycling-js" */),
  "component---src-pages-american-challenge-cable-js": () => import("./../../../src/pages/american-challenge/cable.js" /* webpackChunkName: "component---src-pages-american-challenge-cable-js" */),
  "component---src-pages-american-challenge-epic-js": () => import("./../../../src/pages/american-challenge/epic.js" /* webpackChunkName: "component---src-pages-american-challenge-epic-js" */),
  "component---src-pages-american-challenge-erobot-js": () => import("./../../../src/pages/american-challenge/erobot.js" /* webpackChunkName: "component---src-pages-american-challenge-erobot-js" */),
  "component---src-pages-american-challenge-fast-js": () => import("./../../../src/pages/american-challenge/fast.js" /* webpackChunkName: "component---src-pages-american-challenge-fast-js" */),
  "component---src-pages-american-challenge-fishprotection-js": () => import("./../../../src/pages/american-challenge/fishprotection.js" /* webpackChunkName: "component---src-pages-american-challenge-fishprotection-js" */),
  "component---src-pages-american-challenge-geothermallithiumextraction-js": () => import("./../../../src/pages/american-challenge/geothermallithiumextraction.js" /* webpackChunkName: "component---src-pages-american-challenge-geothermallithiumextraction-js" */),
  "component---src-pages-american-challenge-geothermalmanufacturing-js": () => import("./../../../src/pages/american-challenge/geothermalmanufacturing.js" /* webpackChunkName: "component---src-pages-american-challenge-geothermalmanufacturing-js" */),
  "component---src-pages-american-challenge-groundbreakinghydro-js": () => import("./../../../src/pages/american-challenge/groundbreakinghydro.js" /* webpackChunkName: "component---src-pages-american-challenge-groundbreakinghydro-js" */),
  "component---src-pages-american-challenge-iamhydro-js": () => import("./../../../src/pages/american-challenge/iamhydro.js" /* webpackChunkName: "component---src-pages-american-challenge-iamhydro-js" */),
  "component---src-pages-american-challenge-iteam-js": () => import("./../../../src/pages/american-challenge/iteam.js" /* webpackChunkName: "component---src-pages-american-challenge-iteam-js" */),
  "component---src-pages-american-challenge-oceanobserving-js": () => import("./../../../src/pages/american-challenge/oceanobserving.js" /* webpackChunkName: "component---src-pages-american-challenge-oceanobserving-js" */),
  "component---src-pages-american-challenge-perov-js": () => import("./../../../src/pages/american-challenge/perov.js" /* webpackChunkName: "component---src-pages-american-challenge-perov-js" */),
  "component---src-pages-american-challenge-solardesalination-round-1-js": () => import("./../../../src/pages/american-challenge/solardesalination-round1.js" /* webpackChunkName: "component---src-pages-american-challenge-solardesalination-round-1-js" */),
  "component---src-pages-american-challenge-solardesalination-round-2-js": () => import("./../../../src/pages/american-challenge/solardesalination-round2.js" /* webpackChunkName: "component---src-pages-american-challenge-solardesalination-round-2-js" */),
  "component---src-pages-american-challenge-solarprize-round-1-js": () => import("./../../../src/pages/american-challenge/solarprize-round1.js" /* webpackChunkName: "component---src-pages-american-challenge-solarprize-round-1-js" */),
  "component---src-pages-american-challenge-solarprize-round-2-js": () => import("./../../../src/pages/american-challenge/solarprize-round2.js" /* webpackChunkName: "component---src-pages-american-challenge-solarprize-round-2-js" */),
  "component---src-pages-american-challenge-solarprize-round-3-js": () => import("./../../../src/pages/american-challenge/solarprize-round3.js" /* webpackChunkName: "component---src-pages-american-challenge-solarprize-round-3-js" */),
  "component---src-pages-american-challenge-solarround-4-js": () => import("./../../../src/pages/american-challenge/solarround4.js" /* webpackChunkName: "component---src-pages-american-challenge-solarround-4-js" */),
  "component---src-pages-american-challenge-waterresourcerecovery-js": () => import("./../../../src/pages/american-challenge/waterresourcerecovery.js" /* webpackChunkName: "component---src-pages-american-challenge-waterresourcerecovery-js" */),
  "component---src-pages-american-challenge-wavestowater-js": () => import("./../../../src/pages/american-challenge/wavestowater.js" /* webpackChunkName: "component---src-pages-american-challenge-wavestowater-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-authors-js": () => import("./../../../src/templates/authors.js" /* webpackChunkName: "component---src-templates-authors-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-blogs-es-template-js": () => import("./../../../src/templates/blogs-es-template.js" /* webpackChunkName: "component---src-templates-blogs-es-template-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogs-template.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

